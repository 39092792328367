import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
//import ModalEmail from "./ModalEmail";
//import { Link } from "react-router-dom";
///import ModalEmail from "./ModalEmail";
//import { Link } from "react-router-dom";
///import { useParams } from "react-router-dom";

const Workemail = () => {
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);




  useEffect(() => {
    setLoading(true);
    setError(null);

    fetch("/api/emails")
      .then((res) => res.json())
      .then((data) => setEmails(data.emails))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  console.log(emails);

  if (loading) {
    return <p>Loading email portfolio details...</p>;
  }

  if (error) {
    return <p>Error fetching  eamil portfolio details: {error.message}</p>;
  }
  




  return (
    <div>
      <Container>
        <Row>
          <h1>Email Portfolio</h1>
        </Row>
        <Row>
       
        </Row>
        <Row>
          <div className="pcontainer econtainer">
            {emails.map((emailitem, i) => (
             
                <div key={i}>
                  <div className="scrollbox">
                
                    <div>
                      <Image src={emailitem.imageUrl} fluid />
                    </div>
                  </div>
                </div>
         
            ))}
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Workemail;
///https://codepen.io/devstreak/pen/dMYgeO
////https://codesandbox.io/p/sandbox/react-image-modal-8yvk0?file=%2Fsrc%2Findex.js
