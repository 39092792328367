import React from "react";

const FilterBtn = ({ setSearchParams, typeFilter }) => {
  return (
    <div className="vancontainer ">
 <div className="portfilterbtn">
      <button
        onClick={() => setSearchParams({ type: "React" })}
        className="van-type react "
      >
        React
      </button>

      <button
        onClick={() => setSearchParams({ type: "Javascript" })}
        className="van-type javascript"
      >
        Javascript
      </button>

      {typeFilter ? (
        <button
          className="van-type clearfilters"
          onClick={() => setSearchParams({})}
        >
          Clear Filter
        </button>
      ) : null}
    </div>
    </div>
   
  );
};

export default FilterBtn;
