import { Routes, Route } from "react-router-dom";
import "./server.js";

import "./App.css";
import Layout from "./Components/Layout.jsx";
import Home from "./Pages/Home/Home.jsx";
import About from "./Pages/About/About.jsx";
import Workcoding from "./Pages/Work/Workcoding.jsx";
///import ContactPage from "./Pages/Contact/ContactPage.jsx";
import Archive from "./Pages/Archive/Archive.jsx";
import Worklayout from "./Components/Worklayout.jsx";
import DetailCoding from "./Pages/Work/DetailCoding.jsx";
import Workemail from "./Pages/Work/Workemail.jsx";
import Workarch from "./Pages/Work/Workarch.jsx";
//import ModalEmail from "./Pages/Work/ModalEmail.jsx";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />

          {/* work layout */}

          <Route path="work" element={<Worklayout />}>
            <Route index element={<Workcoding />} />
            <Route path="/work/:id" element={<DetailCoding />} />

            <Route path="email" element={<Workemail />} />
            {/* <Route path="email/:id" element={<ModalEmail />} /> */}

            <Route path="artist" element={<Workarch />} />
          </Route>

          <Route path="/about" element={<About />} />
          {/*  <Route path="/contact" element={<ContactPage />} /> */}
          <Route path="/archive" element={<Archive />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
