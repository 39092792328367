import React from "react";
//import Work from '../Work/Work';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";


const Home = () => {
  //// const [readMore, setReadMore] = useState(false);

  return (
    <div className="home-container">
      <Container>
        <Row xs={1} md={1} lg={2}>
          <Col className="image-col">
            <a href="https://joyful-basbousa-10f9ec.netlify.app/">
              <Image
                src="https://bejewelled-fairy-f18952.netlify.app/static/media/new1.ddb3b8fbedc60be84e6e.jpg"
                alt="Image description"
                fluid
                rounded
              />
            </a>
          </Col>
          <Col className="text-col">
            <h2>Art Gallery</h2>
            <p>
              React App React Interactive art Gallery is a front-end browser
              application that uses third-party data from the Art Institute of
              Chicago API. This React app allows users to search, view, and
              learn more information about the institute of Chicago artwork,
              with an interactive art specific quiz game and informative archive
              and scholar’s section.
              <br />
              <br />
              Features : The Homepage showcase visual section for each UI
              component Art Gallery, Events, Shop, Quiz Game and various
              Publications.
              <br />
              Art gallery - Users can use the search bar to search various
              artist, art styles and mediums. The search will display artwork
              with an interactive hover overlay displaying the title and artist
              and click on artwork to get the specific information page. Events
              – Displaying Upcoming Events.
              <br />
              Shop – Displaying product within the Chicago institute Art Gallery
              Shop linking to the official shop.
              <br />
              Art gallery quiz game – Users answer 10 questions about the Art
              Institute of Chicago, its artwork, style and history.
              <br />
              Archive – Displaying a scroll section that updates the various
              archive within Chicago art gallery.
              <br />
              Scholars – Displaying the title and brief summary of the various
              publications, sections, articles, digital catalogue, printed
              catalogue and educational resources where you click the link for
              more information.
              <br />
              Art gallery Exhibition – Linking to Art Institute of Chicago
              Exhibition Page.
            </p>
          </Col>
        </Row>
     
      </Container>

    </div>
  );
};

export default Home;
/*src="https://cdn.vanderbilt.edu/vu-URL/wp-content/uploads/sites/288/2019/03/19223634/Image-Coming-Soon-Placeholder.png" */
