import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const AboutCV = () => {
  return (
    <div>
      <Row xs={1} md={2} lg={3} className="aboutcv">
        <Col>
          <div className="education">
            <h5>EDUCATION</h5>
            <hr></hr>
            <p>
              <span> University of Plymouth</span>
              <br></br>
              BA Architecture - 2:1
              <br></br>
              Sep 2020
            </p>
            <p>
              <span> Archbishop Tenison’s School 6th Form</span>
              <br></br>A levels
              <br></br>
              Sep 2016
            </p>
            <p>
              <span>St Saviour’s and St Olave’s</span>
              <br></br>
              GCSE
              <br></br>
              Sep 2013
            </p>
          </div>
        </Col>
        <Col>
          <div className="experience">
            <h5>EXPERIENCE</h5>
            <hr></hr>
            <p>
              <span> Email Developer </span>
              <br></br>
              Inbox Warriors / Display Block
              <br></br>
              present
            </p>
            <p>
              <span> English Tutor </span>
              <br></br>
              International English Language Testing 
              <br></br>
              Aug 2023
            </p>
            <p>
              <span> Waitress/Runner </span>
              <br></br>
              The Paxton
              <br></br>
              Aug 2023
            </p>
            <p>
              <span> Summer Sales Assistant </span>
              <br></br>
              Gatwick Airport
              <br></br>
              Aug 2018
            </p>
            <p>
              <span> Summer Office Staff | Art Assistant </span>
              <br></br>
              Camp America
              <br></br>
              Sep 2017
            </p>

            <p>
              <span> Christmas Sales Assistant </span>
              <br></br>
              Card Factory
              <br></br>
              Jan 2017
            </p>
            <p>
              <span> Christmas Sales Assistant</span>
              <br></br>
              Liberty London - Department Store
              <br></br>
              Jan 2016
            </p>
          </div>
        </Col>
        <Col>
          <div className="courses">
            <h5>COURSES | BOOTCAMPS</h5>
            <hr></hr>
            <p>
              <span> Coursera | Professional Certification </span>
              <br></br>
              Google UX Design
            </p>
            <p>
              <span> Coursera | Scrimba Bootcamp </span>
              <br></br>
              Frontend Development Specialization
            </p>
            <p>
              <span> Coursera | Professional Certification </span>
              <br></br>
              Google Digital Marketing & E-commerce
            </p>
            <p>
              <span> SheCodes | Coding Course, Workshop & Challenges</span>
              <br></br>
              Frontend Bootcamp
            </p>
          </div>
        </Col>
      </Row>
      <Row xs={1} md={2} lg={3} className="aboutskill">
        <Col>
          {" "}
          <div className="techskill">
            <h5>TECHNICAL SKILLS</h5>
            <hr></hr>
            <p>HTML</p>
            <p>CSS</p>
            <p>JavaScript</p>
            <p>React</p> 
            <p>Bootstrap</p>
            <p>Material UI</p>
            <p>Editor</p>
            <p>Api | REST Api</p>
            <p>Nodejs</p>
            <p> Hosting</p>
            <p>Cypress</p>
            <p>Sublime Text</p>
            <p>Version Control (Git)</p>
            <p>Microsoft Office</p>
          </div>
        </Col>
        <Col>
          {" "}
          <div className="artkill">
            <h5>DESIGN SKILLS</h5>
            <hr></hr>
            <p>Revit </p>
            <p>AutoCAD</p>
            <p>Dynamo (Python)</p>
            <p>SketchUp</p> 
            <p>Adobe Photoshop</p>
            <p>Adobe Illustrator</p>
            <p>Adobe InDesign</p>
            <p>Figma</p>
            <p>Concept Development</p>
            <p>Illustration</p>
            <p>Design Thinking</p>
           
          </div>
        </Col>
        <Col>
          <div className="softkill">
            <h5>SOFT SKILLS</h5>
            <hr></hr>
            <p>Communication </p>
            <p>Teamwork</p>
            <p>Creativity</p>
            <p>Willingness to learn</p> 
            <p>Responsive Design</p>
            <p>UI/UX Principles</p>
            <p>Optimization techniques</p>
            <p>Design & Technical Research</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AboutCV;
