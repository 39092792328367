import React from "react";
import Nav from "react-bootstrap/Nav";
import { NavLink, Outlet } from "react-router-dom";

const Worklayout = () => {
    const activeStyles = {
    
        textDecoration: "none",
        color: "#161616",
      };

  return (
    <div>
      <div className="workhostnav">
        <Nav className="justify-content-center" activeKey="/work">
          <Nav.Item className="p-2">
            <NavLink
              to="."
              style={({ isActive }) => (isActive ? activeStyles : null)}
            >
              Coding
            </NavLink>
          </Nav.Item>
          <Nav.Item className="p-2">
            <NavLink
              to="email"
              style={({ isActive }) => (isActive ? activeStyles : null)}
            >
              Email
            </NavLink>
          </Nav.Item>
          <Nav.Item className="p-2">
            <NavLink
              to="artist"
              style={({ isActive }) => (isActive ? activeStyles : null)}
            >
              Architecture
            </NavLink>
          </Nav.Item>
        </Nav>
      </div>
       
      <Outlet />
    </div>
  );
};

export default Worklayout;
