import React, { useState } from "react";
import Modal from "react-modal";
///import Image from "react-bootstrap/Image";

const ArtCard = ({ art }) => {
  const [showModal, setShowModal] = useState(false); // State for modal visibility

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      position: "relative", // Use relative positioning instead of absolute
      backgroundColor: "#ffff",
      borderRadius: "8px",
      width: "80%",
      maxHeight: "800px",
      maxWidth: "800px",
      padding: "20px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      justifyContent: "center",
      alignItems: "center",
      overflowY: "auto", // Enable vertical scrolling
      overflowX: "hidden", // Optional: Hide horizontal scrollbar (if needed)
    },
  };

  return (
    <div className="item">
      <div>
        <img src={art.imageartUrl} alt={art.name} onClick={handleOpenModal} />
        <div className="modalcontainer">
          <Modal
            isOpen={showModal}
            onRequestClose={handleCloseModal}
            contentLabel={art.Title}
            ariaHideApp={false}
            style={customStyles}
          >
                         <button onClick={handleCloseModal} className="close btn btncontact" variant="light">Close</button>
           
            <div className="modal-content">
              <img src={art.imageartUrl} alt={art.name} className="modalimg" />

              <div className="modal-header">
                <h2>{art.Title}</h2>
              </div>
              <div className="modal-title">
                <p>{art.description}</p>
              </div>
            </div>


          </Modal>

          {/*   <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        style={customStyles}
        contentLabel={art.Title}
        ariaHideApp={false}
        className="modal"
        overlayClassName='modal-overlay'
      >
        <div className="modal-content">
          <span className="close" onClick={handleCloseModal}>&times;</span>
          <div className="modal-header">
          <img src={art.imageartUrl} alt={art.name}  />
            <div className="modal-title">
              <h2>{art.Title}</h2>
              
            </div>
          </div>
          <div className="modal-body">
           
           
          </div>
        </div>
      </Modal> */}
        </div>
      </div>
    </div>
  );
};

export default ArtCard;
