import React from "react";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

import { Link, NavLink } from "react-router-dom";

const Header = () => {
  const activeStyles = {
    fontWeight: "bold",
    textDecoration: "underline",
    color: "#161616",
  };

  return (
    <div>
        <header>
        <Navbar expand="lg" className="sticky-top ">
        <Container>
          <Navbar.Brand>
            <Link to="/"> Zahra Olanrewaju </Link>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Nav className="justify-content-center" activeKey="/home">
        <Nav.Item className="p-2">
          <NavLink
            to="/work"
            style={({ isActive }) => (isActive ? activeStyles : null)}
          >
            WORK
          </NavLink>
        </Nav.Item>
        <Nav.Item className="p-2">
          <NavLink
            to="/about"
            style={({ isActive }) => (isActive ? activeStyles : null)}
          >
            ABOUT
          </NavLink>
        </Nav.Item>
       {/* <Nav.Item className="p-2">
          <NavLink
            to="/contact"
            style={({ isActive }) => (isActive ? activeStyles : null)}
          >
            CONTACT
          </NavLink>
        </Nav.Item> */}
        <Nav.Item className="p-2">
          <NavLink
            to="/archive"
            style={({ isActive }) => (isActive ? activeStyles : null)}
          >
            ARCHIVE
          </NavLink>
        </Nav.Item>
      </Nav>
        </header>
     
    </div>
  );
};

export default Header;
/* 
 <Nav.Item>
          <Nav.Link href="/home">Active</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="link-1">Link</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="link-2">Link</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="disabled" disabled>
            Disabled
          </Nav.Link>
        </Nav.Item>
        */
