import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaGithub } from "react-icons/fa";
import { TbWorldWww } from "react-icons/tb";

const DetailCoding = () => {
  let iconStyles = { color: "black", fontSize: "1.2em" };

  const params = useParams();
  console.log(params);

  const [portfolios, setPortfolios] = useState(null);

  useEffect(() => {
    fetch(`/api/portfolios/${params.id}`)
      .then((res) => res.json())
      /* .then(data => console.log(data))*/
      .then((data) => setPortfolios(data.portfolio));
  }, [params.id]);

  return (
    <div>
      {/* <h1>Coding detail page goes here</h1> */}
      {portfolios ? (
        <div className="van-detail">
          <Container>
            <Row className="p-2">
              <h2>{portfolios.name}</h2>
            </Row>
            <Row className=" text-description" >
            <Col md={{ span: 8, offset: 2 }}>{portfolios.description}</Col>
     {     /*    <p className="dsecption">{portfolios.description}</p> */}
            </Row>
            <Row className="p-3 justify-content-md-center">
              <Col xs lg="2" className="p-2">
                <span>
                  <a href={portfolios.github}>
                    {" "}
                    <FaGithub style={iconStyles} />{" "}
                  </a>
                </span>
              </Col>
              <Col md="auto" className="p-2">
                <span className="type-span"> {portfolios.type1}</span>
              </Col>
              <Col xs lg="2" className="p-2">
                <span>
                  <a href={portfolios.website}>
                    {" "}
                    <TbWorldWww style={iconStyles} />{" "}
                  </a>
                </span>
              </Col>
            </Row>
            <Image src={portfolios.pimageUrl} fluid />
          </Container>
        </div>
      ) : (
        <h2>Loading...</h2>
      )}
    </div>
  );
};

export default DetailCoding;
