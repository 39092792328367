import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from "react-router-dom";

const Layout = () => {
    return (
        <div className="sitewrapper">
        <Header />
        <main>
          <Outlet />
        </main>
        <Footer />
      </div>
    );
}

export default Layout;
