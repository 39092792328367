import React from 'react';
///import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";


/*const root = ReactDOM.createRoot(document.getElementById('root'));*/
const root = createRoot(document.getElementById("root"));

root.render(

  <BrowserRouter>
  <App />
</BrowserRouter>
/*
  <React.StrictMode>
    <App />
  </React.StrictMode> */
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
