import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import  Row  from "react-bootstrap/Row";

const Archive = () => {
  const [archives, setArchives] = useState([]);
  const [shuffledArchives, setShuffledArchives] = useState([]);


  /*
  useEffect(() => {
    fetch("/api/archives")
      .then((res) => res.json())
      .then((data) => setArchives(data.archives));
      
  }, []); */

  useEffect(() => {
    fetch("/api/archives")
      .then((res) => res.json())
      .then((data) => {
        if (!data) {
          console.error("Error fetching archives data");
          return; 
        }

        setArchives(data.archives);
        setShuffledArchives([...data.archives].sort(() => Math.random() - 0.5));
      })
      .catch((error) => {
        console.error("Error fetching archives data:", error);
      });
  }, []);



  console.log(archives);

  return (
    <Container >
       <Row>
          <h1>Archive Portfolio</h1>
        </Row>
    <div className="results-archive">

      {shuffledArchives.length > 0 ? (
        <div className="card-archive">
          {shuffledArchives.map((arc) => (
            <div key={arc.id} className="">
              <img src={arc.imageUrl} alt="Archive" />
            </div>
          ))}
        </div>
      ) : (
        <p>No archive data available yet.</p>
      )}
    </div>
    </Container>
  );
};

export default Archive;
//https://blog.logrocket.com/adding-zoom-pan-pinch-react-web-apps/