import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
///import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Link, useSearchParams } from "react-router-dom";
import FilterBtn from "./FilterBtn";

const Workcoding = () => {
  const [portfolios, setPortfolios] = useState([]);
  // const [loading, setLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  console.log(searchParams);

  const typeFilter = searchParams.get("type");
  console.log(typeFilter);

  useEffect(() => {
    fetch("/api/portfolios")
      .then((res) => res.json())
      .then((data) => setPortfolios(data.portfolios));
  }, []);

  console.log(portfolios);

  ///Filter;
  const displayedPorts = typeFilter
    ? portfolios.filter((item) => item.type === typeFilter)
    : portfolios;

  const PortElements = /*portfolios*/ displayedPorts.map((item) => (
    <Link to={`${item.id}`} key={item.id}>
      <div className="portwrapper ">
        <div>
          <div className="content">
            <div className="content-overlay"></div>
            <Image src={item.imageUrl} fluid />
            <div className="content-details fadeIn-top">
              <h3 className="">{item.name}</h3>
            { /* <p>This is a short description</p> */}
              <i className={`van-type ${item.type} selected`}>{item.type}</i>
            </div>
          </div>
        </div>
      </div>
    </Link>
  ));

  /*
  
      {
            
                portfolios.map((fruit) => (
                    <div key={fruit.id} >
                        {fruit.name}
                    </div>
                ))
            } */

  return (
    <Container>
      <Row>
        <h1>Coding Portfolio</h1>
      </Row>
      <Row>
        <FilterBtn setSearchParams={setSearchParams} typeFilter={typeFilter} />
      </Row>
      <Row>
        <div className="pcontainer">{PortElements}</div>
      </Row>
      <Row></Row>
    </Container>
  );
};

export default Workcoding;
////https://codepen.io/syrizaldev/pen/PogvYjj
//https://codepen.io/ArnaudBalland/pen/vGZKLr
//https://codepen.io/syrizaldev/pen/PogvYjj
