import React from "react";
import Container from "react-bootstrap/Container";

import AboutProfile from "./AboutProfile";
import AboutCV from "./AboutCV";
const About = () => {
  return (
    <div>
      <Container>
        <div className=" py-3 my-4 ">
      <AboutProfile />
        </div>
        <div className=" py-3 my-4 border-top">
          <AboutCV />
        </div>
      </Container>
    </div>
  );
};

export default About;
