import React from "react";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { FaRegEnvelope } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";

const Footer = () => {

  let iconStyles = { color: "black", fontSize: "1.2em" }; 
  return (
    <div>
      <footer  className="sticky-bottom " >
        <Container>
          <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
            <div className="col-md-4 d-flex align-items-center">
              <Link to="/"> Zahra Olanrewaju </Link>
            </div>
            <Nav className="justify-content-end" >
              <Nav.Item >
                <Nav.Link href="mailto:zahrabola@hotmail.com"><FaRegEnvelope style={iconStyles}/></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="https://github.com/zahrabola"><FaGithub style={iconStyles} /></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="https://www.linkedin.com/in/zahra-olanrewaju-24913019a/"><FaLinkedin  style={iconStyles}/></Nav.Link>
              </Nav.Item>
             
            </Nav>
          </footer>
        </Container>
      </footer>
    </div>
  );
};

export default Footer;
