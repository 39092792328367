import React, { useEffect, useState } from "react";
import ArtCard from "./ArtCard";
import Row from "react-bootstrap/Row";

const Workarch = () => {
  const [artists, setArtists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {

    setLoading(true);
    setError(null);

    fetch("/api/artists")
      .then((res) => res.json())
      .then((data) => setArtists(data.artists))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  console.log(artists);

  
  if (loading) {
    return <p>Loading architecture projects ...</p>;
  }

  if (error) {
    return <p>Error fetching architecture projects: {error.message}</p>;
  }
  
  return (
    <div>
  <Row>
          <h1>Architecture Portfolio</h1>
        </Row>
        <div className="masonry">
       
       {artists.map((art) => (
        <ArtCard art={art} key={art.id} />
       ))}
     </div>
    </div>
   
  );
};

export default Workarch;

/* http://localhost:3000/work?type=React */ ///filter query url
////https://masonry.desandro.com/ 
///https://codepen.io/qq7886/pen/MypEvw
///https://codepen.io/qq7886/pen/MypEvw

/* 
add more images 
https://earnest-cucurucho-bf193d.netlify.app/ - look to old psd images 
*/