import React from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import profileImage from '../../Images/me3.png';


const AboutProfile = () => {
  const profileImagePath = profileImage;  
  return (
    <div>
  
      <Row className="" xs={1} md={2} lg={2}>
        <Col >
        <Image src={profileImagePath} alt="profileimage" className="profileimage" fluid/>
        </Col>
        <Col className=" aboutprofile">
          <h1>Zahra Olanrewaju</h1>
          <h3>
            - I am design focused, bridging the gap between aesthetics and
            functionality, coding and creating digital experiences — currently
            working at Inbox Warriors / Display Block.
          </h3>
          <p>
          I possess a strong foundation in frontend development technologies, creative development tools, and programming languages. I have hands on experience with responsive design and version control. I have excellent communication, problem-solving, and a love for continuous learning, with a keen eye for detail to ensure best practice and a polished user experience.
          </p>
          <p>
            Aside from coding and design, I plays piano, I enjoy swimming and
            painting, and consistently binge episodes of The Big Bang Theory.
          </p>
        
          <div className='getcontact'>
            <h5>Get In Touch </h5>
          <h6>
          Available for questions, collaborations, projects & tea.
          </h6>
          <small>— Based in London</small>

            </div>
          <div md={4} className=" mx-auto pt-3">
                <Button href="mailto:zahrabola@hotmail.com" variant="outline-light"className='btncontact btn'>
                 Email Me! 
                </Button>
              </div>
        </Col>
        <Col>
      
        </Col>
      </Row>
    </div>
  );
};

export default AboutProfile;
